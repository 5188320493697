#MainAppConWithNavigationContentConWallet {
  position: relative;
}

.WalletCon1 {
  box-sizing: border-box;
  background: linear-gradient(#3a7d44 0%, #3a7d441a 25%, #f0e6d2 25.01%), #f0e6d2;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 100%;
  padding: 32px 12px;
  display: flex;
  position: absolute;
  overflow: hidden scroll;
}

.WalletCon2 {
  background: var(--app-white, #fff);
  border-radius: 6px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  display: flex;
  box-shadow: inset 0 0 12px 2px #00000040, 0 4px 12px 2px #00000040;
}

.WalletCon3 {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 12px;
  display: flex;
}

.WalletCon4 {
  border-bottom: 1px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 6px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.WalletCon5 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.WalletText1 {
  color: var(--app-charcoal-dark, #2b2b2b);
  flex: 1 0 0;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.WalletCon6 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 0 6px;
  display: flex;
}

.WalletCon7 {
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.WalletText2 {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.WalletText3 {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.WalletCon8 {
  border: 1px solid var(--app-link-color, #00349b);
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 2px 4px;
  display: flex;
}

.WalletText4 {
  color: var(--app-link-color, #00349b);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.WalletCon9 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 0 12px;
  display: flex;
}

.WalletCon10 {
  border: 1px solid var(--MAI-linear, #03c11f);
  border-radius: 6px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  display: flex;
  box-shadow: 0 2px 4px #00000040;
}

.WalletText5 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.WalletCon11 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 12px;
  display: flex;
}

.WalletCon12 {
  align-self: stretch;
  align-items: center;
  gap: 24px;
  padding: 10px;
  display: flex;
  overflow: scroll hidden;
}

.WalletCon12B {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  padding: 10px;
  display: flex;
}

.WalletCon13 {
  background: var(--app-white, #fff);
  border-radius: 6px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  min-width: 74px;
  padding: 6px;
  display: flex;
  box-shadow: 0 2px 4px 1px #00000040;
}

.WalletCon14 {
  background: var(--MAI-linear, linear-gradient(0deg, #03c11f 0%, #fffb00 100%));
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 64px;
  height: 64px;
  display: flex;
}

.WalletCon15 {
  background: var(--MAI-linear, linear-gradient(0deg, #03c11f 0%, #fffb00 100%));
  border-radius: 3px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 2px 6px;
  display: flex;
}

.WalletText6 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.WalletCon16 {
  background: var(--app-white, #fff);
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
  box-shadow: 0 4px 12px 2px #00000040;
}

.WalletCon17 {
  background: var(--app-gray-soft, #e0e0e0);
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 6px 12px;
  display: flex;
}

.WalletCon18 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  padding: 6px 0;
  display: flex;
}

.WalletCon18MaxHeight {
  max-height: 250px;
  overflow: hidden scroll;
}

.WalletMoveCon1 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 0 12px;
  display: flex;
}

.WalletMoveCon2 {
  border-bottom: .5px solid var(--app-gray-soft, #e0e0e0);
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  padding: 6px;
  display: flex;
}

.WalletMoveCon3 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.WalletMoveCon4 {
  flex-direction: column;
  flex: 1 0 0;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.WalletText7 {
  color: var(--app-charcoal-dark, #2b2b2b);
  align-self: stretch;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.WalletMoveCon5 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 0 6px;
  display: flex;
}

.WalletText8 {
  color: var(--app-gray-medium, #6d6d6d);
  flex: 1 0 0;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.WalletMoveCon6 {
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.WalletText9 {
  color: var(--app-gray-soft);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.WalletMoveCon7 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-end;
  display: flex;
}

.WalletMoveCon8 {
  background: var(--app-gray-soft);
  border-radius: 0 3px 3px 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 2px 6px;
  display: flex;
}

.WalletMoveCon1In {
  background: var(--money-in-background-color, #eff9f0);
}

.WalletText9In {
  color: var(--Success-color-green, #33b546);
}

.WalletMoveCon8In {
  background: var(--Success-color-green, #33b546);
}

.WalletMoveCon1Out {
  background: var(--money-out-background-color, #ffebeb);
}

.WalletText9Out {
  color: var(--Danger-color-red, red);
}

.WalletMoveCon8Out {
  background: var(--Danger-color-red, red);
}

.WalletText10 {
  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.WalletText11 {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.WalletCon19 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  display: flex;
}

.WalletText13 {
  color: var(--app-green, #3a7d44);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.WalletConOverlay {
  box-sizing: border-box;
  background: #00000073;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  overflow: hidden scroll;
}

.WalletCon20A {
  box-sizing: border-box;
  width: 100%;
  padding: 0 12px;
}

.WalletCon20 {
  background: var(--app-white, #fff);
  box-sizing: border-box;
  border-radius: 12px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  max-width: 500px;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  box-shadow: inset 0 0 12px 4px #00000040, 0 4px 12px 2px #00000080;
}

.WalletCon21 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  display: flex;
}

.Wallet2Text1 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.WalletCon22 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  display: flex;
}

.WalletCon23 {
  border-bottom: 1px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.Wallet2Text2 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.WalletCon24 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.WalletCon25 {
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.WalletCon26 {
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.Wallet2Text3 {
  color: var(--app-gray-soft);
  flex: 1 0 0;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.WalletCon27 {
  background: var(--app-gray-soft);
  border-radius: 3px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  display: flex;
}

.Wallet2Text4 {
  color: var(--app-white, #fff);
  text-align: center;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.WalletCon24Success {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.WalletCon25Success {
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.WalletCon26Success {
  flex: 1 0 0;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  display: flex;
}

.Wallet2Text5 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.WalletCon27Success {
  background: var(--Success-color-green, #33b546);
  border-radius: 3px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  display: flex;
}

.WalletCon28 {
  box-sizing: border-box;
  background: var(--MAI-linear, linear-gradient(0deg, #03c11f 0%, #fffb00 100%));
  border-radius: 6px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 6px 12px;
  display: flex;
  box-shadow: inset 0 0 4px 1px #00000040, 0 2px 6px 1px #00000040;
}

.WalletCon30 {
  box-sizing: border-box;
  border-radius: 6px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 6px 12px;
  display: flex;
  box-shadow: 0 2px 6px 1px #00000040;
}

.Wallet2Text6B {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.WalletCon29 {
  background: var(--app-charcoal-dark, #2b2b2b);
  border-radius: 6px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  display: flex;
  box-shadow: 0 2px 6px 1px #00000040;
}

.Wallet2Text6 {
  color: var(--app-gold-yellow, #f4c542);
  text-align: center;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Wallet2Text3Error {
  color: var(--Danger-color-red, red);
}

.WalletCon27Error {
  background: var(--Danger-color-red, red);
}

.Wallet2Text3Success {
  color: var(--Success-color-green, #33b546);
}

.WalletCon27Succes {
  background: var(--Success-color-green, #33b546);
}

.WalletCon30ASuccess {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  padding: 12px;
  display: flex;
}

.WalletCon30Success {
  background: var(--app-gray-soft);
  box-sizing: border-box;
  border-radius: 12px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 10px;
  margin: 0 auto;
  padding: 24px 12px;
  transition: width .3s ease-in-out, height .3s ease-in-out;
  display: flex;
  overflow: hidden;
  box-shadow: inset 0 0 12px 6px #00000040, 0 4px 12px 2px #00000080;
}

.WalletCon31Success {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6px;
  display: flex;
}

.WalletCon32Success {
  border-radius: 3px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  display: flex;
}

.Wallet3Text1Success {
  color: var(--app-white, #fff);
  text-align: center;
  align-self: stretch;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Wallet3Text2Success {
  color: var(--app-white, #fff);
  text-align: center;
  align-self: stretch;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Wallet3ButtonClose {
  background: var(--app-white, #fff);
  color: var(--Success-color-green, #33b546);
  text-align: center;
  border-radius: 6px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 6px 12px;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  box-shadow: inset 0 0 12px 2px #00000040, 0 4px 12px 2px #00000040;
}

.WalletCon30ProcessingVideoCon {
  background: var(--app-white, #fff);
  border-radius: 6px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  height: 24px;
  display: flex;
  box-shadow: inset 0 0 12px 2px #00000040, 0 4px 12px 2px #00000040;
}

.WalletCon30SuccessProcess {
  background: var(--app-white, #fff);
}

.WalletCon30SuccessTextGreen {
  color: var(--Success-color-green, #33b546);
}

.WalletCon30SuccessSuccess {
  background: var(--Success-color-green, #33b546);
}

.WalletCon30SuccessTextWhite {
  color: var(--app-white, #fff);
}

.WalletCon30SuccessError {
  background: var(--Danger-color-red, red);
}

.WalletCon22New {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  display: flex;
}

.Wallet2Text2New {
  color: var(--app-gray-medium, #6d6d6d);
  align-self: stretch;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.WalletCon23New {
  border-bottom: 1px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 6px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.WalletConTermsConditionsNew {
  border: 1px solid var(--app-link-color, #00349b);
  border-radius: 3px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 6px;
  display: flex;
}

.WalletConTermsConditionsNewText {
  color: var(--app-link-color, #00349b);
  text-decoration-skip-ink: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: auto;
}

#paypal-button-container {
  width: 100%;
}

#PaypalButtonContainerMain {
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 32px 12px;
  display: flex;
}

.WalletConMoneyIn1 {
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  display: flex;
}

.WalletConMoneyIn2 {
  background: var(--app-green, #3a7d44);
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 62px;
  height: 24px;
  padding: 2px 4px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.WalletConMoneyInText1 {
  color: #fff;
  text-align: center;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.WalletConMoneyIn3 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.WalletConMoneyIn4 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  display: flex;
}

.WalletConMoneyIn5 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.WalletConMoneyInButtonCon1 {
  background: var(--app-white, #fff);
  box-sizing: border-box;
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 6px 12px;
  display: flex;
  box-shadow: 0 2px 6px 1px #00000040;
}

.WalletConMoneyInButtonCon2 {
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  display: flex;
}

.WalletConMoneyInButtonText1 {
  color: #00a6ed;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.WalletConMoneyInButtonText2 {
  color: #2790c3;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.WalletConMoneyInButtonText3 {
  color: #4a90e2;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

#WalletConOverlayMoneyInInputAmount {
  border-bottom: 1px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 6px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.WalletConMoneyInButtonCon1NoneAct {
  opacity: .3;
}

.WalletConPedingOrder1 {
  background: var(--app-white, #fff);
  border-radius: 6px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  padding: 12px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 2px 4px 1px #00000040;
}

.WalletConPedingOrder2 {
  border-bottom: 1px solid var(--app-gray-soft, #e0e0e0);
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-end;
  padding: 2px 0;
  display: flex;
}

.WalletConPedingOrder3 {
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.WalletConPedingOrder4 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}
/*# sourceMappingURL=index.26111d10.css.map */
