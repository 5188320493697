#MainAppConWithNavigationContentConWallet {
  position: relative;
}
.WalletCon1 {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  padding: 32px 12px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  background: linear-gradient(
      180deg,
      #3a7d44 0%,
      rgba(58, 125, 68, 0.1) 25%,
      #f0e6d2 25.01%
    ),
    #f0e6d2;
  overflow: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
}
.WalletCon2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--app-white, #fff);
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.25) inset,
    0px 4px 12px 2px rgba(0, 0, 0, 0.25);
}
.WalletCon3 {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.WalletCon4 {
  display: flex;
  width: 100%;
  padding: 6px;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.WalletCon5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.WalletText1 {
  flex: 1 0 0;
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.WalletCon6 {
  display: flex;
  padding: 0px 6px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.WalletCon7 {
  display: flex;
  align-items: flex-start;
  gap: 6px;
}
.WalletText2 {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.WalletText3 {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.WalletCon8 {
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  border: 1px solid var(--app-link-color, #00349b);
}
.WalletText4 {
  color: var(--app-link-color, #00349b);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.WalletCon9 {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.WalletCon10 {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--MAI-linear, #03c11f);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.WalletText5 {
  flex: 1 0 0;
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.WalletCon11 {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.WalletCon12 {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  overflow: hidden;
  overflow-x: scroll;
}
.WalletCon12B {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.WalletCon13 {
  display: flex;
  min-width: 74px;
  padding: 6px;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  border-radius: 6px;
  background: var(--app-white, #fff);
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
}
.WalletCon14 {
  display: flex;
  width: 64px;
  height: 64px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
}
.WalletCon15 {
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 3px;
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
}
.WalletText6 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.WalletCon16 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 6px; */
  align-self: stretch;
  background: var(--app-white, #fff);
  box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.25);
}
.WalletCon17 {
  display: flex;
  padding: 6px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--app-gray-soft, #e0e0e0);
}
.WalletCon18 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  padding: 6px 0px;
  align-self: stretch;
}
.WalletCon18MaxHeight {
  max-height: 250px;
  overflow: hidden;
  overflow-y: scroll;
}
.WalletMoveCon1 {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.WalletMoveCon2 {
  display: flex;
  padding: 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-bottom: 0.5px solid var(--app-gray-soft, #e0e0e0);
}
.WalletMoveCon3 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.WalletMoveCon4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  flex: 1 0 0;
}
.WalletText7 {
  align-self: stretch;
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.WalletMoveCon5 {
  display: flex;
  padding: 0px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.WalletText8 {
  flex: 1 0 0;
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.WalletMoveCon6 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.WalletText9 {
  color: var(--app-gray-soft);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.WalletMoveCon7 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}
.WalletMoveCon8 {
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 0px 3px 3px 0px;
  background: var(--app-gray-soft);
}

/* Start in */
.WalletMoveCon1In {
  background: var(--money-in-background-color, #eff9f0);
}
.WalletText9In {
  color: var(--Success-color-green, #33b546);
}
.WalletMoveCon8In {
  background: var(--Success-color-green, #33b546);
}
/* End in */

/* Start out */
.WalletMoveCon1Out {
  background: var(--money-out-background-color, #ffebeb);
}
.WalletText9Out {
  color: var(--Danger-color-red, #f00);
}
.WalletMoveCon8Out {
  background: var(--Danger-color-red, #f00);
}
/* End out */

.WalletText10 {
  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.WalletText11 {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.WalletCon19 {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.WalletText13 {
  color: var(--app-green, #3a7d44);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* 

/* 

/* 

/* 

/* 

/* Sarts Wallet CheckOut */
.WalletConOverlay {
  position: absolute;
  /* display: none; */
  display: flex;
  /* padding: 12px; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.45);
  overflow: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
}
.WalletCon20A {
  width: 100%;
  padding: 0 12px;
  box-sizing: border-box;
}
.WalletCon20 {
  max-width: 500px;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--app-white, #fff);
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.25) inset,
    0px 4px 12px 2px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  margin: 0 auto;
}
.WalletCon21 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.Wallet2Text1 {
  flex: 1 0 0;
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.WalletCon22 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.WalletCon23 {
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.Wallet2Text2 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.WalletCon24 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.WalletCon25 {
  display: flex;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.WalletCon26 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}
.Wallet2Text3 {
  flex: 1 0 0;
  color: var(--app-gray-soft);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.WalletCon27 {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 3px;
  background: var(--app-gray-soft);
}
.Wallet2Text4 {
  flex: 1 0 0;
  color: var(--app-white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.WalletCon24Success {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.WalletCon25Success {
  display: flex;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.WalletCon26Success {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
}
.Wallet2Text5 {
  /* flex: 1 0 0; */
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.WalletCon27Success {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 3px;
  background: var(--Success-color-green, #33b546);
}
.WalletCon28 {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  align-self: stretch;
  box-sizing: border-box;
  border-radius: 6px;
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25) inset,
    0px 2px 6px 1px rgba(0, 0, 0, 0.25);
}
.WalletCon30 {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  align-self: stretch;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.25);
}
.Wallet2Text6B {
  flex: 1 0 0;
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.WalletCon29 {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--app-charcoal-dark, #2b2b2b);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.25);
}
.Wallet2Text6 {
  flex: 1 0 0;
  color: var(--app-gold-yellow, #f4c542);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Start error */
.Wallet2Text3Error {
  color: var(--Danger-color-red, #f00);
}
.WalletCon27Error {
  background: var(--Danger-color-red, #f00);
}
/* End error */
/* Start success */
.Wallet2Text3Success {
  color: var(--Success-color-green, #33b546);
}
.WalletCon27Succes {
  background: var(--Success-color-green, #33b546);
}
/* End success */

/* 

/* 

/* 

/* 

/* 

/* */
.WalletCon30ASuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 12px;
  align-self: stretch;
}
.WalletCon30Success {
  display: flex;
  padding: 24px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--app-gray-soft);
  box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.25) inset,
    0px 4px 12px 2px rgba(0, 0, 0, 0.5);
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
  /* Smooth transition for width */
  width: 10px; /* Initial width */
  box-sizing: border-box;
  overflow: hidden;
  margin: 0 auto;
}

.WalletCon31Success {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
}
.WalletCon32Success {
  display: flex;
  padding: 6px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 3px;
}
.Wallet3Text1Success {
  align-self: stretch;
  color: var(--app-white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.Wallet3Text2Success {
  align-self: stretch;
  color: var(--app-white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Wallet3ButtonClose {
  display: flex;
  padding: 6px 12px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 6px;
  background: var(--app-white, #fff);
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.25) inset,
    0px 4px 12px 2px rgba(0, 0, 0, 0.25);

  color: var(--Success-color-green, #33b546);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.WalletCon30ProcessingVideoCon {
  display: flex;
  height: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  border-radius: 6px;
  background: var(--app-white, #fff);
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.25) inset,
    0px 4px 12px 2px rgba(0, 0, 0, 0.25);
}

/* process Start */
.WalletCon30SuccessProcess {
  background: var(--app-white, #fff);
}
.WalletCon30SuccessTextGreen {
  color: var(--Success-color-green, #33b546);
}

/* process End */
/* success Start */
.WalletCon30SuccessSuccess {
  background: var(--Success-color-green, #33b546);
}
.WalletCon30SuccessTextWhite {
  color: var(--app-white, #fff);
}

/* success End */
/* error Start */
.WalletCon30SuccessError {
  background: var(--Danger-color-red, #f00);
}

/* error End */

.WalletCon22New {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.Wallet2Text2New {
  align-self: stretch;

  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.WalletCon23New {
  display: flex;
  padding: 6px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.WalletConTermsConditionsNew {
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 3px;
  border: 1px solid var(--app-link-color, #00349b);
}
.WalletConTermsConditionsNewText {
  flex: 1 0 0;
  color: var(--app-link-color, #00349b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

/* Start PayPal */

#paypal-button-container {
  width: 100%;
}
#PaypalButtonContainerMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  max-width: 500px;
  padding: 32px 12px;
  box-sizing: border-box;
  margin: 0 auto;
}

/* Start Money In */
.WalletConMoneyIn1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.WalletConMoneyIn2 {
  display: flex;
  width: 62px;
  height: 24px;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 3px;
  background: var(--app-green, #3a7d44);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.WalletConMoneyInText1 {
  flex: 1 0 0;

  color: #fff;
  text-align: center;
  /* leading-trim: both; */
  /* text-edge: cap; */
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.WalletConMoneyIn3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.WalletConMoneyIn4 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.WalletConMoneyIn5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.WalletConMoneyInButtonCon1 {
  display: flex;
  width: 100%;
  flex-direction: column; /* Change direction to stack children */
  justify-content: center; /* Center children vertically */
  align-items: center; /* Center children horizontally */
  padding: 6px 12px;
  border-radius: 6px;
  background: var(--app-white, #fff);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
}

.WalletConMoneyInButtonCon2 {
  display: flex;
  align-items: center;
  justify-content: center; /* Ensure items inside Con2 are centered */
  gap: 12px;
  width: 100%; /* Stretch full width */
}

.WalletConMoneyInButtonText1 {
  color: #00a6ed;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.WalletConMoneyInButtonText2 {
  color: #2790c3;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.WalletConMoneyInButtonText3 {
  color: #4a90e2;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
#WalletConOverlayMoneyInInputAmount {
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;

  border-bottom: 1px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.WalletConMoneyInButtonCon1NoneAct {
  opacity: 0.3;
}

/*  

/*  

/*  

/*  

/*  

*/

.WalletConPedingOrder1 {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--app-white, #fff);
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.WalletConPedingOrder2 {
  display: flex;
  padding: 2px 0px;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
  border-bottom: 1px solid var(--app-gray-soft, #e0e0e0);
}
.WalletConPedingOrder3 {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.WalletConPedingOrder4 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
